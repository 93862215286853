<template>
  <a :class="buttonStyle" class="button-action">
    <span v-if="loading" style="margin: 0 3px 0 -8px;">
      <span class="progress" />
    </span>
    <slot />
  </a>
</template>
<script>
  import buttonStyleMixin from '../mixins/button-style';

  export default {
    name: 'ButtonAction',
    mixins: [buttonStyleMixin],
    props: {
      loading: Boolean,
    },
  };
</script>
<style lang="scss" scoped>
.progress {
  display: inline-block;
  width: 12px;
  height: 12px;
}

.progress::after {
  content: " ";
  display: block;
  width: 9px;
  height: 9px;
  margin: 0;
  border-radius: 50%;
  border: 2px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: progress 1.2s linear infinite;
}

@keyframes progress {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
