<template>
  <div class="form-container" :class="{ 'form-input-error' : error}">
    <input
      :id="name"
      ref="inputobj"
      maxlength="100"
      class="form-input"
      :class="{ 'form-label-error' : error}"
      placeholder=" "
      :type="type"
      :name="name"
      :autocomplete="autocomplete"
      :disabled="loading"
      :required="required"
      :value="valuefilter"
      :autofocus="autofocus"
      @input="updateInput()"
      @keyup.down="$emit('down')"
      @keyup.up="$emit('up')"
      @keyup.enter="$emit('onSubmit')"
      @blur="$emit('blur')"
      @focus="$emit('focus')">
    <label
      :for="name"
      class="form-label"
      :class="{ 'form-label-error' : error}">{{ title }}</label>
  </div>
</template>

<script>
import { phoneFilter } from '@/shared/utils/filters';

  export default {
    name: 'FCInput',
    props: {
      title: {
        type: String,
        default: '',
      },
      type: {
        type: String,
        default: '',
      },
      value: {
        type: String,
        default: '',
      },
      error: Boolean,
      loading: Boolean,
      name: {
        type: String,
        default: '',
      },
      autocomplete: {
        type: String,
        default: '',
      },
      focus: Boolean,
      required: {
        type: Boolean,
        default: true,
      },
      autofocus: Boolean,
    },
    filters: {
      phone: phoneFilter,
    },
    mounted() {
      setTimeout(() => {
        if (this.focus) {
          this.$refs.inputobj.focus();
        }
      }, 150);
    },
    computed: {
      valuefilter() {
        if (this.type === 'tel') { return this.$options.filters.phone(this.value); }
        return this.value;
      },
    },
    methods: {
      updateInput() {
        this.$emit('input', this.$refs.inputobj.value);
      },
    },

  };
</script>

<style scoped lang="scss">

  .form-container {
    width: 100%;
    position: relative;
  }

  .form-input {
    position: relative;
    width: 100%;
    border-radius: $input-radius;
    background-color: white;
    box-sizing: border-box;
    border: 2px solid $fc-greyscale-700;
    font-size: 16px;
    padding: 12px 18px 4px 18px;
    margin: 0;
    color: #000;
    -webkit-appearance: none;
  }

  .form-label {
    position: absolute;
    pointer-events: none;
    left: 20px;
    top: 12px;
    transition: 0.2s ease all;
    color: $color-black;
    font-size: 16px;
  }

  .form-input-error input,
  .form-input-error input:focus {
    border-color: $color-accent;
  }

  input:disabled ~ .form-label {
    top: 4px;
    bottom: 10px;
    font-size: 10px;
    opacity: 1;
  }

  input:focus ~ .form-label {
    top: 4px;
    bottom: 10px;
    font-size: 10px;
    opacity: 1;
  }

  input:not(:empty) ~ .form-label {
    top: 4px;
    bottom: 10px;
    font-size: 10px;
    opacity: 1;
  }

  input:not(:placeholder-shown) ~ .form-label {
    top: 4px;
    bottom: 10px;
    font-size: 10px;
    opacity: 1;
  }

  .form-label-error {
    color: $color-accent;
  }

  .form-input:disabled {
    background-color: $color_light_gray;
  }

  .form-input:focus {
    border: 2px solid $color-black;
  }
</style>
