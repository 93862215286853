const handlePhoneFilter = (phone) => (phone || '')
  .toString()
  .replace(/[^0-9]/g, '')
  .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');

export const phoneFilter = (num) => {
  const string = typeof num == 'number' ? num.toString() : num
  if (string == null || !string.length) return '';
  return handlePhoneFilter(string)
}
