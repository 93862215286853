<template>
  <div class="cart-frame">
    <div class="item-container-overflow-top" />
    <div class="item-container">
      <div class="item-container-scroll;">
        <div v-for="item in filteredBundle" :key="item.productName">
          <div class="item">
            <div class="item-info-qty">
              {{ item.qty }}
            </div>
            <div class="item-info">
              <div class="item-info-main">
                <div v-if="isShopifyOn" class="item-info-name">
                  {{ item.title }}
                </div>
                <div v-else class="item-info-name">
                  {{ item.productName }}
                </div>
                <div class="item-info-description">
                  {{ item.pricingDetails }}
                </div>
              </div>
              <div class="item-info-description2">
                {{ item.pricingDescription }}
              </div>
              <p v-if="item.items" class="item-include-list-title">
                Includes:
              </p>
              <ul v-if="item.items" class="item-include-list">
                <li
                  v-for="pack_item in item.items"
                  :key="pack_item.name"
                  class="item-include-list-item">
                  {{ pack_item.name }}
                </li>
              </ul>
            </div>
            <div v-if="item.price" class="item-price">
              {{ item.price * item.qty | currency }}
            </div>
            <div v-else-if="item.oldPrice" class="item-price">
              <s>{{ item.oldPrice | currency }} </s>
              FREE
            </div>
            <div v-else class="item-price" />
            <div
              v-if="enableRemove && !(item.type === 'subs_only' || item.type === 'deal' || item.type === 'shipping')"
              class="remove-button"
              v-track-click="`${$options.name}_RemoveItem`"
              @click="removeItem(item)">
              <font-awesome-icon :icon="['fas', 'times']" />
            </div>
          </div>
        </div>
        <div
          v-for="item in subscription"
          :key="item.productName">
          <div class="item">
            <div class="item-info-qty">
              1
            </div>
            <div class="item-info">
              <div class="item-info-main">
                <div class="item-info-name">
                  {{ item.productName }} <span class="item-info-name-modal" @click="openMembershipModal">ⓘ</span>
                </div>
                <div class="item-info-description">
                  <span v-if="item.id==='FightCamp_1_MonthC'">
                    ${{ membershipAmount }}/mo
                  </span>
                  {{ item.pricingDescription }}
                  {{ taxesText }}
                </div>
              </div>
            </div>
            <div v-if="item.price > 0" class="item-price">
              {{ item.price | currency }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="computedBundle.length === 0 && cartSyncCompleted" class="cart-empty">
        <h2> Cart Empty</h2>
      </div>
    </div>
    <div class="item-container-overflow-bottom" />
    <div class="txshp-container" :class="{processingTotalCost}">
      <div class="txshp-row">
        <div class="item-txshp-title">
          Subtotal:
        </div>
        <div class="item-txshp-price">
          {{ subtotalLine | currency }}
        </div>
      </div>
      <div v-if="(showPromo && promoEnabled) || (cartCoupon && promoEnabled)" class="promo-row">
        <div class="promo-block">
          <div class="item-promo-title">
            Promo Code:
          </div>
          <input
            v-model="inputcoupon"
            :disabled="cartCoupon"
            class="input-discount"
            :class="{'input-discount-error': cartCouponError}">
          <a
            v-if="!(cartCoupon || processingTotalCost) "
            class="promo-button"
            v-track-click="`${$options.name}_ApplyPromo`"
            @click="clickApplyCoupon()">Apply</a>
          <font-awesome-icon
            v-if="cartCoupon && promoEnabled"
            :icon="['fas', 'times']"
            @click="clearCoupon()" />
        </div>
        <div class="item-promo-price">
          <div> {{ couponLine }} </div>
        </div>
      </div>
      <p v-if="cartCouponError" class="promo-error">
        {{ cartCouponError }}
      </p>

      <div class="txshp-row">
        <div class="item-txshp-title">
          Shipping:
        </div>
        <div class="item-txshp-price">
          <div v-if="shippingLine != null ">
            {{ shippingLine | currency }}
          </div>
          <div v-else>
            --
          </div>
        </div>
      </div>
      <div class="txshp-row">
        <div class="item-txshp-title">
          Tax:
        </div>
        <div class="item-txshp-price">
          <div v-if="taxLine != null">
            {{ taxLine | currency }}
          </div>
          <div v-else>
            --
          </div>
        </div>
      </div>
      <div v-if="!showPromo && promoEnabled" class="promo-row">
        <a
          class="item-txshp-title promo-button"
          v-track-click="`${$options.name}_EnterPromo`"
          @click="showPromo=true">Enter Promo Code</a>
      </div>
      <div v-if="processingTotalCost" class="txshp-processing">
        <font-awesome-icon
          :icon="['fas', 'circle-notch']"
          spin
          class="spinner" />
      </div>
    </div>
    <div class="final-container">
      <div class="final-label">
        Order total:
      </div>
      <div class="final-price">
        {{ totalLine | currency }}
      </div>
      <div v-if="displayExcludesText" class="final-details">
        Excludes shipping and tax.
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import utils from '@/shared/scripts/utils';
  import variants from '@/shared/datasets/variants';
  import EventBus from '@/shared/scripts/eventbus';
  import { Actions } from '@/shared/store/actionsTypes';
  import { couponsForDealItems } from '@/shared/datasets/coupons';

  export default {
    name: 'CartFrame',
    props: {
      enableRemove: Boolean,
      enableAffirm: Boolean,
      enablePromo: Boolean,
      cartContent: {
        type: Object,
        default: null,
      },
      isShopifyOn: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      showPromo: false,
      inputcoupon: '',
      error: false,
      showMessage: false,
      validCoupon: {},
    }),
    methods: {
      removeItem(item) {
        this.$store.dispatch('removeItemFromCart', item);
        const fullVariant = variants.find(v => v.id === item.id);
        EventBus.$emit('trackRemoveFromCart', [fullVariant]);
        this.$analytics.productRemoved(fullVariant);
      },
      clearCoupon() {
        this.$analytics.couponRemoved(this.inputcoupon);
        this.$store.dispatch('clearCoupon', this.validCoupon);
        this.inputcoupon = '';
        this.showMessage = false;
        this.validCoupon = {};
      },
      clickApplyCoupon() {
        this.$analytics.couponEntered(this.inputcoupon);
        this.applyCoupon().then((data) => {
          EventBus.$emit('trackPromoCodeAttempted', {
            coupon: this.inputcoupon,
            valid: data.couponValid,
            error: data.error,
          });

          this.$analytics.trackPromoCodeAttempted(
            this.inputcoupon,
            data.couponValid,
            data.error,
          );
          if (!data.error) {
            this.validCoupon = data;
            this.$analytics.couponApplied(this.inputcoupon);
            if (couponsForDealItems.includes(data.description)) {
              this.showMessage = true;
            }
          } else {
            this.$analytics.couponDenied(this.inputcoupon);
          }
        }).catch(() => {});
      },

      applyCoupon() {
        if (this.cartqty === 0) {
          this.$store.commit('updateCouponError', 'At least one item is needed to apply this coupon.');
          return Promise.resolve();
        }

        if (!this.canApplyAccessoryCoupon) {
          this.$store.commit('updateCouponError', 'At least one accessory item is needed to apply this coupon.');
          return Promise.resolve();
        }

        const minAmount = this.accessoryCoupons[this.inputcoupon];

        if (this.accessoryTotal < minAmount) {
          this.$store.commit('updateCouponError', `Accessory total does not meet $${parseFloat(minAmount / 100.0).toFixed(0)} minimum.`);
          return Promise.resolve();
        }

        return this.$store.dispatch('updateCoupon', this.inputcoupon);
      },
      isReferralCode(amount) {
        return amount < 100;
      },
      openMembershipModal() {
        this.$store.dispatch(Actions.OPEN_MODAL, { component: 'membership' });
      },
    },
    computed: {
      ...mapGetters([
        'bundle',
        'processingTotalCost',
        'summary_subtotal',
        'summary_tax',
        'summary_shipping',
        'summary_total',
        'summary_coupon',
        'amountoff',
        'cartCoupon',
        'cartCouponError',
        'cart',
        'order',
        'cartqty',
        'cartSyncCompleted',
        'contactEmail',
        'initStarted',
        'CURRENT_VERSION',
        'membershipTaxes',
        'accessoryTotal',
        'accessoryCoupons',
        'canApplyAccessoryCoupon',
        'hasPackageWithBag',
        'isGQOrder'
      ]),
      computedBundle() {
        if (this.isShopifyOn && !this.isGQOrder) { return this.cartContent.cart;}

        const { cart } = this.cartContent || {};
        return (cart || []).length ? cart.map((item) => {
          const desc = variants.find(product => item.id === product.id);
          desc.id = item.id;
          desc.qty = item.qty;
          desc.type = item.type;
          return desc;
        }) : this.bundle;
      },
      filteredBundle() {
        return this.computedBundle.filter(item => item.type !== 'subs_only');
      },
      promoEnabled() {
        return this.enablePromo && this.contactEmail && this.contactEmail !== '';
      },
      subscription() {
        return this.computedBundle.filter(item => item.type === 'subs_only');
      },
      packageInCart() {
        return !!this.computedBundle.find(item => item.type === 'subs_only');
      },
      isConnect() {
        return this.bundle.find(item => (['connect_V2_L', 'connect_V2_S'].includes(item.id)));
      },
      subtotalLine() {
        if (this.cartContent) {
          return this.cartContent.subtotal;
        }
        return this.summary_subtotal;
      },
      couponAmount() {
        let amount = 0;
        if (this.cartContent && this.cartContent.coupon) {
          amount = Math.abs(this.cartContent.coupon);
        } else if (this.summary_coupon) {
          amount = Math.abs(this.summary_coupon);
        } else if (this.amountoff) {
          amount = Math.abs(this.amountoff);
        }
        return amount;
      },
      couponLine() {
        const amount = this.couponAmount;
        if (amount === 0) {
          return '--';
        }
        if (this.isReferralCode(amount)) {
          return 'APPLIED';
        }
        const displayedAmount = -1 * amount;
        return this.$options.filters.currency(displayedAmount);
      },
      shippingLine() {
        if (this.cartContent) {
          return this.cartContent.shipping;
        }
        return this.summary_shipping;
      },
      taxLine() {
        if (this.cartContent) {
          return this.cartContent.tax;
        }
        return this.summary_tax;
      },
      totalLine() {
        if (this.cartContent) {
          return this.cartContent.total;
        }
        return this.summary_total;
      },
      membershipTaxesPresent() {
        return this.membershipTaxes || this.membershipTaxes === 0;
      },
      membershipAmount() {
        if (this.membershipTaxesPresent) {
          return 39 + this.membershipTaxes;
        }
        return 39;
      },
      taxesText() {
        if (this.membershipTaxes > 0) {
          return 'Local taxes included.';
        }
        return 'Local taxes may apply.';
      },
      displayExcludesText() {
        return !this.order && this.$route.name !== 'shop-confirmation'
      },
    },
    watch: {
      inputcoupon() {
        if (this.inputcoupon) {
          this.inputcoupon = this.inputcoupon.toUpperCase().replace(/\s/g, '');
        }
        this.$store.commit('clearCouponError');
      },
      cartCoupon() {
        this.inputcoupon = this.cartCoupon;
      },
      cart() {
        if (this.cartqty) {
          if (!this.cartCoupon && this.inputcoupon) {
            this.applyCoupon().catch(() => {});
          }
        } else {
          this.$store.dispatch('clearCoupon', null);
        }
      },
    },

    mounted() {
      if (this.cartCoupon) {
        this.showPromo = true;
      }
      this.inputcoupon = this.cartCoupon;
      const queryCoupon = utils.getParameterByName('coupon');
      if (queryCoupon) {
        this.showPromo = true;
        this.inputcoupon = queryCoupon;
        this.applyCoupon().catch(() => {});
      }
    },
  };

</script>

<style scoped lang="scss">
  .cart-frame {
    box-sizing: border-box;
    padding: 10px 0;
    background-color: $color-cart-background;
    color: $color-cart-text;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    h2 {
      text-align: center;
      text-transform: uppercase;
      margin: 20px;
    }

    .item-container {
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      flex: 1 1 auto;
      overflow: auto;
    }
  }

  .cart-empty {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    color: $color-cart-light-text;
  }

  .item-container-scroll {
    width: 100%;
    height: 100%;
    overflow: auto;
  }

  .item-container-overflow-top {
    margin-bottom: -15px;
    z-index: 100;
    width: 100%;
    height: 40px;
    border-top: 0.5px solid $color-cart-border;
    background: linear-gradient(to top, $color-cart-overflow-0, $color-cart-overflow-1);
  }

  .item-container-overflow-bottom {
    margin-top: -15px;
    z-index: 100;
    width: 100%;
    height: 40px;
    background: linear-gradient(to bottom, $color-cart-overflow-0, $color-cart-overflow-1);
    border-bottom: 0.5px solid $color-cart-border;
  }

  .txshp-container {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    padding: 10px 0;
    border-bottom: 0.5px solid $color-cart-border;
  }

  .txshp-processing {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
  }

  .promo-row,
  .txshp-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 4px 0;
  }

  .promo-block {
    flex: 1 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .item-txshp-title {
    font-size: 14px;
    font-family: $font-stack-regular;
  }

  .item-txshp-price {
    font-family: $font-stack-regular;
    font-size: 16px;
    min-width: 80px;
    padding-left: 24px;
    text-align: right;

    @media #{$iphone-5} {
      font-size: 14px;
    }

    &-free {
      margin-right: 5px;
    }
  }

  .item-promo-title {
    font-size: 14px;
    font-family: $font-stack-regular;
  }

  .item-promo-price {
    font-family: $font-stack-regular;
    font-size: 16px;
    padding-left: 10px;
    text-align: right;

    @media #{$iphone-5} {
      font-size: 14px;
    }
  }

  .final-container {
    width: 100%;
    padding-top: 20px;
    text-align: center;
  }

  .final-label {
    font-family: $font-stack-regular;
    font-size: 18px;
    padding-bottom: 5px;
  }

  .final-price {
    font-family: $font-stack-regular;
    font-size: 18px;
    padding-bottom: 10px;
  }

  .final-details {
    font-family: $font-stack-light;
    font-size: 12px;
  }

  .item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    width: 100%;
    margin: 16px 0;
  }

  .item-info {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: 8px;
    text-align: left;
    flex: 1 1 auto;
  }

  .item-info-main {
    display: inline-block;
    box-sizing: border-box;
  }

  .item-info-qty {
    flex: 0 0 20px;
    font-size: 16px;
    font-family: $font-stack-bold;

    @media #{$small-device-up} {
      flex: 0 0 40px;
    }
  }

  .item-info-name {
    display: inline-block;
    font-family: $font-stack-regular;
    padding-bottom: 10px;
    font-size: 14px;

    @media #{$small-device-up} {
      font-size: 16px;
    }

    &-modal {
      font-size: 13px;
      cursor: pointer;
      vertical-align: bottom;
      font-weight: bold;
    }
  }

  .item-info-description {
    display: inline-block;
    font-family: $font-stack-light;
    font-size: 12px;
    color: $color-cart-light-text;

    @media #{$small-device-up} {
      font-size: 14px;
    }
  }

  .item-info-description2 {
    font-family: $font-stack-light;
    font-size: 12px;
    line-height: 1.25;
    color: $color-cart-light-text;

    @media #{$small-device-up} {
      font-size: 14px;
    }
  }

  .item-price {
    display: inline-block;
    font-family: $font-stack-regular;
    font-size: 14px;
    text-align: left;

    @media #{$small-device-up} {
      font-size: 16px;
    }
  }

  .item-include-list {
    font-size: 12px;
    padding: 0 10px;
    margin: 0 20px;
  }

  .item-include-list-title {
    color: $color-cart-light-text;
    font-size: 12px;
    margin: 0;
    padding: 5px 10px 0 0;

    @media #{$small-device-up} {
      font-size: 14px;
    }
  }

  .item-include-list li {
    font-size: 12px;
    padding: 0;
    color: $color-cart-light-text;

    @media #{$small-device-up} {
      font-size: 14px;
    }
  }

  .remove-button {
    padding-left: 5px;
  }

  .spinner {
    color: $color-cart-spinner;
    font-size: 24px;
  }

  .processingTotalCost {
    color: #aaa;
  }

  .promo-button {
    font-size: 14px;
    text-decoration: underline;
    color: $color-cart-link;
  }

  .input-discount {
    max-width: 110px;
    font-size: 12px;
    border-radius: 100px;
    padding: 0 10px;
    -webkit-appearance: none;
    border: $border-light-outline;
    margin-left: 5px;
    margin-right: 5px;
    font-family: "Courier New", Courier, monospace;

    @media #{$iphone-5} {
      max-width: 90px;
    }
  }

  .input-discount:disabled {
    background-color: $color_light_gray;
  }

  .input-discount-error {
    border-color: $color-accent;
    color: $color-accent;
  }

  .promo-error {
    margin: 0;
    color: $color-accent;
    font-size: 14px;
    text-align: left;
  }

  .promo-valid {
    margin: 0;
    font-size: 14px;
    text-align: left;
  }

  .affirm-as-low-as {
    text-align: center;
  }

  .message {
    background-color: $color-blue;
    color: white;
    margin: 10px 0;
    padding: 10px;
    border-radius: $container-radius;
    text-align: left;

    @media #{$small-device-up} {
      margin: 10px 0 10px 10px;
      padding: 20px 15px;
    }

    &-title {
      font-size: 14px;
      font-family: $font-stack-bold;
      text-align: left;
      color: white;

      @media #{$small-device-up} {
        font-size: 16px;
      }
    }

    &-text-1 {
      font-size: 14px;
      text-align: left;
      color: white;
      margin: 10px 0 0 0;
    }

    &-text-2 {
      font-size: 14px;
      text-align: left;
      color: white;
      margin: 5px 0 0 0;
    }
  }
</style>
